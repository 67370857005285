exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-activities-js": () => import("./../../../src/pages/activities.js" /* webpackChunkName: "component---src-pages-activities-js" */),
  "component---src-pages-activities-mission-1-all-aboard-the-aquabus-js": () => import("./../../../src/pages/activities/mission-1/all-aboard-the-aquabus.js" /* webpackChunkName: "component---src-pages-activities-mission-1-all-aboard-the-aquabus-js" */),
  "component---src-pages-activities-mission-1-js": () => import("./../../../src/pages/activities/mission-1.js" /* webpackChunkName: "component---src-pages-activities-mission-1-js" */),
  "component---src-pages-activities-mission-1-quiz-js": () => import("./../../../src/pages/activities/mission-1/quiz.js" /* webpackChunkName: "component---src-pages-activities-mission-1-quiz-js" */),
  "component---src-pages-activities-mission-1-speedy-shower-challenge-js": () => import("./../../../src/pages/activities/mission-1/speedy-shower-challenge.js" /* webpackChunkName: "component---src-pages-activities-mission-1-speedy-shower-challenge-js" */),
  "component---src-pages-activities-mission-1-spot-the-difference-js": () => import("./../../../src/pages/activities/mission-1/spot-the-difference.js" /* webpackChunkName: "component---src-pages-activities-mission-1-spot-the-difference-js" */),
  "component---src-pages-activities-mission-1-water-saving-game-js": () => import("./../../../src/pages/activities/mission-1/water-saving-game.js" /* webpackChunkName: "component---src-pages-activities-mission-1-water-saving-game-js" */),
  "component---src-pages-activities-mission-1-we-are-the-aquanauts-js": () => import("./../../../src/pages/activities/mission-1/we-are-the-aquanauts.js" /* webpackChunkName: "component---src-pages-activities-mission-1-we-are-the-aquanauts-js" */),
  "component---src-pages-activities-mission-2-bring-the-garden-to-life-js": () => import("./../../../src/pages/activities/mission-2/bring-the-garden-to-life.js" /* webpackChunkName: "component---src-pages-activities-mission-2-bring-the-garden-to-life-js" */),
  "component---src-pages-activities-mission-2-drinking-water-diary-js": () => import("./../../../src/pages/activities/mission-2/drinking-water-diary.js" /* webpackChunkName: "component---src-pages-activities-mission-2-drinking-water-diary-js" */),
  "component---src-pages-activities-mission-2-js": () => import("./../../../src/pages/activities/mission-2.js" /* webpackChunkName: "component---src-pages-activities-mission-2-js" */),
  "component---src-pages-activities-mission-2-make-a-rain-maker-js": () => import("./../../../src/pages/activities/mission-2/make-a-rain-maker.js" /* webpackChunkName: "component---src-pages-activities-mission-2-make-a-rain-maker-js" */),
  "component---src-pages-activities-mission-2-quiz-js": () => import("./../../../src/pages/activities/mission-2/quiz.js" /* webpackChunkName: "component---src-pages-activities-mission-2-quiz-js" */),
  "component---src-pages-activities-mission-2-rain-cloud-in-a-jar-js": () => import("./../../../src/pages/activities/mission-2/rain-cloud-in-a-jar.js" /* webpackChunkName: "component---src-pages-activities-mission-2-rain-cloud-in-a-jar-js" */),
  "component---src-pages-activities-mission-2-scavenger-hunt-js": () => import("./../../../src/pages/activities/mission-2/scavenger-hunt.js" /* webpackChunkName: "component---src-pages-activities-mission-2-scavenger-hunt-js" */),
  "component---src-pages-activities-mission-3-animal-picture-search-js": () => import("./../../../src/pages/activities/mission-3/animal-picture-search.js" /* webpackChunkName: "component---src-pages-activities-mission-3-animal-picture-search-js" */),
  "component---src-pages-activities-mission-3-js": () => import("./../../../src/pages/activities/mission-3.js" /* webpackChunkName: "component---src-pages-activities-mission-3-js" */),
  "component---src-pages-activities-mission-3-make-your-own-river-js": () => import("./../../../src/pages/activities/mission-3/make-your-own-river.js" /* webpackChunkName: "component---src-pages-activities-mission-3-make-your-own-river-js" */),
  "component---src-pages-activities-mission-3-quiz-js": () => import("./../../../src/pages/activities/mission-3/quiz.js" /* webpackChunkName: "component---src-pages-activities-mission-3-quiz-js" */),
  "component---src-pages-activities-mission-3-saving-water-dance-js": () => import("./../../../src/pages/activities/mission-3/saving-water-dance.js" /* webpackChunkName: "component---src-pages-activities-mission-3-saving-water-dance-js" */),
  "component---src-pages-activities-mission-3-stop-the-leaking-water-js": () => import("./../../../src/pages/activities/mission-3/stop-the-leaking-water.js" /* webpackChunkName: "component---src-pages-activities-mission-3-stop-the-leaking-water-js" */),
  "component---src-pages-activities-mission-3-water-saving-poster-js": () => import("./../../../src/pages/activities/mission-3/water-saving-poster.js" /* webpackChunkName: "component---src-pages-activities-mission-3-water-saving-poster-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cookies-js": () => import("./../../../src/pages/cookies.js" /* webpackChunkName: "component---src-pages-cookies-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-schools-js": () => import("./../../../src/pages/schools.js" /* webpackChunkName: "component---src-pages-schools-js" */),
  "component---src-pages-terms-of-use-js": () => import("./../../../src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */)
}

